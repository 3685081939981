import React from "react";
import MIDI from "../midi/midi";

const _DELAY = 50;

class NoteInput extends EventTarget {

  static _instance;

  _timer;
  _notes = [];

  static getInstance() {
    if (!NoteInput._instance) {
      NoteInput._instance = new NoteInput();
    }
    return NoteInput._instance;
  }

  constructor() {
    super();
    this._handleReady = this._handleReady.bind(this);
    this._handlePortsChanged = this._handlePortsChanged.bind(this);
    this._listenToPort = this._listenToPort.bind(this);
    this._handleNoteOff = this._handleNoteOff.bind(this);
    this._handleNoteOn = this._handleNoteOn.bind(this);
    this._notifyChange = this._notifyChange.bind(this);
    const midi = new MIDI(this._handleReady, this._handlePortsChanged);
    midi.start();
  }

  _handleReady(event) {
    event.ports.forEach(port => this._listenToPort(port));
  }

  _handlePortsChanged(event) {
    if (event.type === "add") {
      this._listenToPort(event.port);
    }
  }

  _listenToPort(port) {
    port.onNoteOff = this._handleNoteOff;
    port.onNoteOn = this._handleNoteOn;
  }

  _handleNoteOff(event) {
    if (this._notes.indexOf(event.note) !== -1) {
      const remainingNotes = this._notes.filter(note => note !== event.note);
      this._notes = remainingNotes;
      this._notifyChange(remainingNotes);
    }
  }

  _handleNoteOn(event) {
    if (this._notes.indexOf(event.note) === -1) {
      const newNotes = [...this._notes, event.note];
      newNotes.sort();
      this._notes = newNotes;
      this._notifyChange(newNotes);
    }
  }

  _notifyChange(notes) {
    if (this._timer) {
      clearTimeout(this._timer);
    }
    this._timer = setTimeout(() => {
      const event = new Event("change");
      event.notes = notes;
      this.dispatchEvent(event);
    }, _DELAY);
  }

}

function useNoteInput(onChange) {
  const noteInput = NoteInput.getInstance();
  React.useEffect(() => {
    onChange && noteInput.addEventListener("change", onChange);
    return () => {
      onChange && noteInput.removeEventListener("change", onChange);
    }
  });

  return noteInput;
}

export {
  NoteInput,
  useNoteInput,
}