import React from "react";
import * as ChordQuality from "./chordQualities";
import * as Symbols from "./symbols";
import {KeyCenters, Qualities, Quality, Scales, Tonality} from "./scales";

class Chord {

  scale;
  root;
  notes;
  type;
  mode;

  static _chordTones(scaleTones, scaleDegree, topDegree, inversion) {
    const chordTones = [];
    for (let degree = 2*inversion; degree <= topDegree; degree += 2) {
      chordTones.push(scaleTones[(scaleDegree + degree) % scaleTones.length]);
    }
    return chordTones;
  }

  static _chordType(chordQuality, topTension) {
    switch (chordQuality) {
      case ChordQuality.MAJOR:
        return `${Symbols.MAJ}${topTension}`;
      case ChordQuality.MINOR:
        return `${Symbols.MIN}${topTension}`;
      case ChordQuality.MINOR_MAJOR:
        return `${Symbols.MIN}${Symbols.MAJ}${topTension}`;
      case ChordQuality.DOMINANT:
        return `${topTension}`;
      case ChordQuality.HALF_DIMINISHED:
        return Math.random() > 0.5 ? Symbols.HDIM : `${Symbols.MIN}7${Symbols.FLAT}5`;
      case ChordQuality.DIMINISHED:
        return `${Symbols.DIM}7`;
      case ChordQuality.AUGMENTED_MAJOR:
        return `${Symbols.AUG}${Symbols.MAJ}7`;
      default:
        throw new Error("undefined chord quality");
    }
  }

  constructor(scale, degree, inversion) {
    const quality = scale.chordQualities[degree];
    const topDegree = 6 // 7th chord -- but could allow upper extensions;
    const topTension = topDegree + 1;
    const notes = Chord._chordTones(scale.scaleTones, degree, topDegree, inversion);
    this.scale = scale;
    this.notes = notes;
    this.root = notes[0];
    this.type = Chord._chordType(quality, topTension)
    this.mode = scale.modes[degree];
  }

  translate(notes) {
    return notes.map(note => this.scale.translate(note));
  }

  matches(playedNotes, strict = false) {
    const played = playedNotes.map(note => note.semitone);
    const expected = this.notes.map(note => note.semitone);
    return played.length === expected.length
        && (played.every(e => strict ?
            expected.indexOf(e) === played.indexOf(e)
            : expected.indexOf(e) !== -1));
  }

  isSameAs(chord) {
    return chord.scale && chord.scale.isSameAs(this.scale)
        && chord.root && chord.root === this.root;
  }

}

class _RandomChordGenerator {

  tonality;
  _keyPredicate;

  constructor(tonality, filterPredicate) {
    this.tonality = tonality;
    this._keyPredicate = filterPredicate;
  }

  next() {
    const key = KeyCenters.random(this.tonality.quality, this._keyPredicate);
    const degree = Math.floor(Math.random() * 7);
    return [this.tonality, key, degree];
  }

}

class _ProgressionGenerator {

  tonality;
  _keyPredicate;
  _key;
  _degrees;
  _index;

  constructor(tonality, degrees, filterPredicate) {
    this.tonality = tonality;
    this._degrees = [...degrees];
    this._keyPredicate = filterPredicate;
    this._index = this._degrees.length;
  }

  next() {
    if (this._index >= this._degrees.length) {
      this._key = KeyCenters.random(this.tonality.quality, this._keyPredicate);
      this._index = 0;
    }
    return [this.tonality, this._key, this._degrees[this._index++]];
  }

}

class _TwoFiveGenerator {

  static _TARGET_DEGREES = [0, 1, 2, 3, 5];

  _keys;
  _chords;
  _excludeTarget;
  _index;

  constructor(keys) {
    this.keys = keys;
  }

  set keys(keys) {
    if (!keys || !keys.length) {
      keys = [...KeyCenters.major];
    }
    this._keys = keys;
    this._chords = [];
    this._index = 0;
  }

  get keys() {
    return [...this._keys];
  }

  next() {
    if (this._index >= this._chords.length) {
      const targetKey = this._keys[Math.floor(this._keys.length * Math.random())];
      const targetDegree = _TwoFiveGenerator._TARGET_DEGREES[Math.floor(_TwoFiveGenerator._TARGET_DEGREES.length * Math.random())];
      const targetScale = Scales.select(Tonality.major, targetKey);
      const targetQuality = Qualities.major[targetDegree];
      const useMinor = targetQuality === ChordQuality.MINOR && Math.random() > 0.50;
      console.log("targetKey", targetKey);
      console.log("targetDegree", targetDegree);
      console.log("targetScale", targetScale);
      console.log("targetQuality", targetQuality);
      console.log("useMinor", useMinor);

      // const relatedKey = useMinor ?
      //     KeyCenters.findById(Quality.minor, `${targetScale.scaleTones[targetDegree].id}-`)
      //     : KeyCenters.findById(Quality.major, targetScale.scaleTones[targetDegree].id);
      const relatedKey = KeyCenters.findBySemitone(
          useMinor ? Quality.minor : Quality.major, targetScale.scaleTones[targetDegree].semitone);
      const relatedScale = Scales.select(
          useMinor ? Tonality.harmonicMinor : Tonality.major, relatedKey);
      console.log("relatedKey", relatedKey);
      console.log("relatedScale", relatedScale);

      this._chords = [
        [relatedScale.tonality, relatedKey, 1],
        [relatedScale.tonality, relatedKey, 4]
      ];
      if (!this._excludeTarget) {
        this._chords.push([targetScale.tonality, targetKey, targetDegree]);
      }
      console.log("chords", this._chords);
      this._index = 0;
    }
    return this._chords[this._index++];
  }
}

const Generators = {
  random: (tonality, filterPredicate = () => true) => new _RandomChordGenerator(tonality, filterPredicate),
  progression: (tonality, degrees, filterPredicate = () => true) => new _ProgressionGenerator(tonality, degrees, filterPredicate),
  twoFive: (keys = []) => new _TwoFiveGenerator(keys),
}

class Chords extends EventTarget {

  static _instance;

  inversion = 0;
  _last;
  _generator;

  static getInstance() {
    if (!Chords._instance) {
      Chords._instance = new Chords();
    }
    return Chords._instance;
  }

  constructor() {
    super();
    this.generator = Generators.random(Tonality.major);
    this.next();
  }

  get generator() {
    return this._generator;
  }

  set generator(generator) {
    this._generator = generator;
    const event = new Event("changeGenerator");
    event.generator = generator;
    this.dispatchEvent(event);
  }

  selected() {
    return this._last;
  }

  next() {
    const [tonality, key, degree] = this._generator.next();
    const scale = Scales.select(tonality, key);
    this._last = new Chord(scale, degree, this.inversion);
    const event = new Event("next");
    event.chord = this._last;
    this.dispatchEvent(event);
    return this._last;
  }

}

function useChords(onChangeGenerator = null, onNext = null) {
  const chords = Chords.getInstance();
  React.useEffect(() => {
    onChangeGenerator && chords.addEventListener("changeGenerator", onChangeGenerator);
    onNext && chords.addEventListener("next", onNext);
    return () => {
      onChangeGenerator && chords.removeEventListener("changeGenerator", onChangeGenerator);
      onNext && chords.removeEventListener("next", onNext);
    };
  }, [chords, onChangeGenerator, onNext]);

  return chords;
}

export {
  Chords,
  Generators,
  useChords,
}
