import React from "react";


import Flashcard from "./Flashcard";
import {useNoteInput} from "../midi/noteInput";
import {useChords} from "../model/chords";


export default function FlashcardDeck() {
  const timer = React.useRef(null);
  const chords = useChords(handleGeneratorChange, handleChordChange);
  const [chord, setChord] = React.useState(chords.selected());
  const [notes, setNotes] = React.useState([]);
  const [correct, setCorrect] = React.useState(false);
  const [showBack, setShowBack] = React.useState(false);

  useNoteInput((event) => {
    setNotes(event.notes);
  })

  const nextCard = React.useCallback(() => {
    chords.next();
    setNotes([]);
    setCorrect(false);
  }, [chords]);

  React.useEffect(() => {
    if (chord && !correct) {
      setCorrect(chord.matches(chord.translate(notes)));
    }
  }, [chord, correct, notes]);

  React.useEffect(() => {
    if (correct && notes && notes.length === 0) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      setShowBack(false);
      setTimeout(nextCard, 250);
    }
  }, [correct, notes, nextCard]);

  function flipCard(event) {
    if (!showBack) {
      setShowBack(true);
    }
    else {
      event.stopPropagation();
      setShowBack(false);
      if (timer.current) {
        clearTimeout(timer.current);
      }
      // select the next card after a delay to avoid revealing it
      setTimeout(nextCard, 250);
    }
  }

  function handleGeneratorChange(event) {
    if (showBack) {
      flipCard(event);
    }
    else {
      nextCard();
    }
  }

  function handleChordChange(event) {
    setChord(event.chord);
  }

  return (
      chord && <Flashcard chord={chord}
                          notes={chord.translate(notes)}
                          correct={correct}
                          showBack={showBack}
                          onFlip={flipCard}/>
  );
}
