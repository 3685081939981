
export const MAJ = "\u0394";
export const MIN = "-";
export const FLAT = "\u266D";
export const NATURAL = "\u266E";
export const SHARP = "\u266F";
export const DOUBLE_FLAT = "\u{1D12B}";
export const DOUBLE_SHARP = "\u{1D12A}";
export const DIM = "\u{1d1c8}";
export const AUG = "+";
export const HDIM = "\u2205";
