import * as Protocol from "./protocol";

export default class Port {

	id;
	name;
	onNoteOn;
	onNoteOff;
	_delegate;
	_runningStatus;

	constructor(delegate) {
		this.id = delegate.id;
		this.name = delegate.name;
		this._delegate = delegate;
		this.destroy = this.destroy.bind(this);
		this._handleInput = this._handleInput.bind(this);
		this._handleNoteOff = this._handleNoteOff.bind(this);
		delegate.addEventListener("midimessage", this._handleInput);
		const manufacturer = delegate.manufacturer || "unknown";
		console.log(`discovered port: ${delegate.name} (${manufacturer})`);
	}

	destroy() {
		this._delegate.removeEventListener("midimessage", this._handleInput);
		delete this._delegate
	}

	_handleInput(event) {
		let status = event.data[Protocol.STATUS];
		if (status < Protocol.NOTE_OFF) {
			if (!this._runningStatus) return;
			status = this._runningStatus;
		}
		if (status === Protocol.NOTE_OFF || (status === Protocol.NOTE_ON && event.data[Protocol.NOTE_VELOCITY] === 0)) {
			this._handleNoteOff(event.data)
		}
		else if (status === Protocol.NOTE_ON) {
			this._handleNoteOn(event.data);
		}
	}

	_handleNoteOff(data) {
		this.onNoteOff && this.onNoteOff({
				"type": "noteOff", 
				"note": data[Protocol.NOTE_NUM], 
				"velocity": data[Protocol.NOTE_VELOCITY],
				"port": this,
		});
	}

	_handleNoteOn(data) {
		this.onNoteOn && this.onNoteOn({
				"type": "noteOn", 
				"note": data[Protocol.NOTE_NUM], 
				"velocity": data[Protocol.NOTE_VELOCITY],
				"port": this,
		});
	}

}
