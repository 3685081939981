import * as Symbols from "./symbols";

export const A_FLAT = `A${Symbols.FLAT}`;
export const A_NATURAL = "A";
export const A_SHARP = `A${Symbols.SHARP}`;
export const B_FLAT = `B${Symbols.FLAT}`;
export const B_NATURAL = "B";
export const B_SHARP = `B${Symbols.SHARP}`;
export const C_FLAT = `C${Symbols.FLAT}`;
export const C_NATURAL = "C";
export const C_SHARP = `C${Symbols.SHARP}`;
export const D_FLAT = `D${Symbols.FLAT}`;
export const D_NATURAL = "D";
export const D_SHARP = `D${Symbols.SHARP}`;
export const E_FLAT = `E${Symbols.FLAT}`;
export const E_NATURAL = "E";
export const E_SHARP = `E${Symbols.SHARP}`;
export const F_FLAT = `F${Symbols.FLAT}`;
export const F_NATURAL = "F";
export const F_SHARP = `F${Symbols.SHARP}`;
export const G_FLAT = `G${Symbols.FLAT}`;
export const G_NATURAL = "G";
export const G_SHARP = `G${Symbols.SHARP}`;
export const G_DOUBLE_SHARP = `G${Symbols.DOUBLE_SHARP}`;
