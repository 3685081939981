import * as ChordQuality from "./chordQualities";
import * as Notes from "./notes";
import * as Protocol from "../midi/protocol";
import * as Symbols from "./symbols";

const MODULUS = 12;

const _CHROMATIC_SEMITONES_FLAT = [Notes.C_NATURAL, Notes.D_FLAT, Notes.D_NATURAL, Notes.E_FLAT, Notes.E_NATURAL, Notes.F_NATURAL, Notes.G_FLAT, Notes.G_NATURAL, Notes.A_FLAT, Notes.A_NATURAL, Notes.B_FLAT, Notes.B_NATURAL];
const _CHROMATIC_SEMITONES_SHARP = [Notes.C_NATURAL, Notes.C_SHARP, Notes.D_NATURAL, Notes.D_SHARP, Notes.E_NATURAL, Notes.F_NATURAL, Notes.F_SHARP, Notes.G_NATURAL, Notes.G_SHARP, Notes.A_NATURAL, Notes.A_SHARP, Notes.B_NATURAL];


const _CHROMATIC_SCALE_TONES = {
  "C": _CHROMATIC_SEMITONES_SHARP,
  "F": _CHROMATIC_SEMITONES_FLAT,
  "Bb": _CHROMATIC_SEMITONES_FLAT,
  "Eb": _CHROMATIC_SEMITONES_FLAT,
  "Ab": _CHROMATIC_SEMITONES_FLAT,
  "Db": _CHROMATIC_SEMITONES_FLAT,
  "C#": [Notes.B_SHARP, Notes.C_SHARP, Notes.D_NATURAL, Notes.D_SHARP, Notes.E_NATURAL, Notes.E_SHARP, Notes.F_SHARP, Notes.G_NATURAL, Notes.G_SHARP, Notes.A_NATURAL, Notes.A_SHARP, Notes.B_NATURAL],
  "Gb": [Notes.C_NATURAL, Notes.D_FLAT, Notes.D_NATURAL, Notes.E_FLAT, Notes.E_NATURAL, Notes.F_NATURAL, Notes.G_FLAT, Notes.G_NATURAL, Notes.A_FLAT, Notes.A_NATURAL, Notes.B_FLAT, Notes.C_FLAT],
  "F#": [Notes.C_NATURAL, Notes.C_SHARP, Notes.D_NATURAL, Notes.D_SHARP, Notes.E_NATURAL, Notes.E_SHARP, Notes.F_SHARP, Notes.G_NATURAL, Notes.G_SHARP, Notes.A_NATURAL, Notes.A_SHARP, Notes.B_NATURAL],
  "Cb": [Notes.C_NATURAL, Notes.D_FLAT, Notes.D_NATURAL, Notes.E_FLAT, Notes.F_FLAT, Notes.F_NATURAL, Notes.G_FLAT, Notes.G_NATURAL, Notes.A_FLAT, Notes.A_NATURAL, Notes.B_FLAT, Notes.C_FLAT],
  "B": _CHROMATIC_SEMITONES_SHARP,
  "E": _CHROMATIC_SEMITONES_SHARP,
  "A": _CHROMATIC_SEMITONES_SHARP,
  "D": _CHROMATIC_SEMITONES_SHARP,
  "G": _CHROMATIC_SEMITONES_SHARP,
  "A-": [Notes.C_NATURAL, Notes.C_SHARP, Notes.D_NATURAL, Notes.E_FLAT, Notes.E_NATURAL, Notes.F_NATURAL, Notes.F_SHARP, Notes.G_NATURAL, Notes.G_SHARP, Notes.A_NATURAL, Notes.B_FLAT, Notes.B_NATURAL],
  "D-": [Notes.C_NATURAL, Notes.C_SHARP, Notes.D_NATURAL, Notes.E_FLAT, Notes.E_NATURAL, Notes.F_NATURAL, Notes.F_SHARP, Notes.G_NATURAL, Notes.A_FLAT, Notes.A_NATURAL, Notes.B_FLAT, Notes.B_NATURAL],
  "G-": [Notes.C_NATURAL, Notes.D_FLAT, Notes.D_NATURAL, Notes.E_FLAT, Notes.E_NATURAL, Notes.F_NATURAL, Notes.F_SHARP, Notes.G_NATURAL, Notes.A_FLAT, Notes.A_NATURAL, Notes.B_FLAT, Notes.B_NATURAL],
  "C-": _CHROMATIC_SEMITONES_FLAT,
  "F-": _CHROMATIC_SEMITONES_FLAT,
  "Bb-": _CHROMATIC_SEMITONES_FLAT,
  "A#-": [Notes.B_SHARP, Notes.C_SHARP, Notes.D_NATURAL, Notes.D_SHARP, Notes.E_NATURAL, Notes.E_SHARP, Notes.F_SHARP, Notes.G_NATURAL, Notes.G_SHARP, Notes.G_DOUBLE_SHARP, Notes.A_SHARP, Notes.B_NATURAL],
  "Eb-": _CHROMATIC_SEMITONES_FLAT,
  "D#-": [Notes.B_SHARP, Notes.C_SHARP, Notes.D_NATURAL, Notes.D_SHARP, Notes.E_NATURAL, Notes.E_SHARP, Notes.F_SHARP, Notes.G_NATURAL, Notes.G_SHARP, Notes.A_NATURAL, Notes.A_SHARP, Notes.B_NATURAL],
  "Ab-": [Notes.C_NATURAL, Notes.D_FLAT, Notes.D_NATURAL, Notes.E_FLAT, Notes.E_NATURAL, Notes.F_NATURAL, Notes.G_FLAT, Notes.G_NATURAL, Notes.A_FLAT, Notes.A_SHARP, Notes.B_FLAT, Notes.C_FLAT],
  "G#-": [Notes.B_SHARP, Notes.C_SHARP, Notes.D_NATURAL, Notes.D_SHARP, Notes.E_NATURAL, Notes.E_SHARP, Notes.F_SHARP, Notes.G_NATURAL, Notes.G_SHARP, Notes.A_NATURAL, Notes.A_SHARP, Notes.B_NATURAL],
  "C#-": [Notes.B_SHARP, Notes.C_SHARP, Notes.D_NATURAL, Notes.D_SHARP, Notes.E_NATURAL, Notes.E_SHARP, Notes.F_SHARP, Notes.G_NATURAL, Notes.G_SHARP, Notes.A_NATURAL, Notes.A_SHARP, Notes.B_NATURAL],
  "F#-": [Notes.B_SHARP, Notes.C_SHARP, Notes.D_NATURAL, Notes.D_SHARP, Notes.E_NATURAL, Notes.E_SHARP, Notes.F_SHARP, Notes.G_NATURAL, Notes.G_SHARP, Notes.A_NATURAL, Notes.A_SHARP, Notes.B_NATURAL],
  "B-": _CHROMATIC_SEMITONES_SHARP,
  "E-": _CHROMATIC_SEMITONES_SHARP,
};

function _randomKeyCenter(availableKeyCenters) {
  const selectedIndex = Math.floor(availableKeyCenters.length * Math.random());
  return {...availableKeyCenters[selectedIndex]};
}

const Quality = {
  major: "major",
  minor: "minor",
}

const KeyCenters = {
  major: [
    {id: "C", semitone: 0, name: Notes.C_NATURAL, quality: Quality.major, hard: false},
    {id: "F", semitone: 5, name: Notes.F_NATURAL, quality: Quality.major, hard: false},
    {id: "Bb", semitone: 10, name: Notes.B_FLAT, quality: Quality.major, hard: false},
    {id: "Eb", semitone: 3, name: Notes.E_FLAT, quality: Quality.major, hard: false},
    {id: "Ab", semitone: 8, name: Notes.A_FLAT, quality: Quality.major, hard: true},
    {id: "Db", semitone: 1, name: Notes.D_FLAT, quality: Quality.major, hard: true},
    {id: "C#", semitone: 1, name: Notes.C_SHARP, quality: Quality.major, hard: true},
    {id: "Gb", semitone: 6, name: Notes.G_FLAT, quality: Quality.major, hard: true},
    {id: "F#", semitone: 6, name: Notes.F_SHARP, quality: Quality.major, hard: true},
    {id: "Cb", semitone: 11, name: Notes.C_FLAT, quality: Quality.major, hard: true},
    {id: "B", semitone: 11, name: Notes.B_NATURAL, quality: Quality.major, hard: true},
    {id: "E", semitone: 4, name: Notes.E_NATURAL, quality: Quality.major, hard: true},
    {id: "A", semitone: 9, name: Notes.A_NATURAL, quality: Quality.major, hard: false},
    {id: "D", semitone: 2, name: Notes.D_NATURAL, quality: Quality.major, hard: false},
    {id: "G", semitone: 7, name: Notes.G_NATURAL, quality: Quality.major, hard: false},
  ],
  minor: [
    {id: "A-", semitone: 9, name: Notes.A_NATURAL, quality: Quality.minor, hard: false},
    {id: "D-", semitone: 2, name: Notes.D_NATURAL, quality: Quality.minor, hard: false},
    {id: "G-", semitone: 7, name: Notes.G_NATURAL, quality: Quality.minor, hard: false},
    {id: "C-", semitone: 0, name: Notes.C_NATURAL, quality: Quality.minor, hard: false},
    {id: "F-", semitone: 5, name: Notes.F_NATURAL, quality: Quality.minor, hard: true},
    {id: "Bb-", semitone: 10, name: Notes.B_FLAT, quality: Quality.minor, hard: true},
    {id: "A#-", semitone: 10, name: Notes.A_SHARP, quality: Quality.minor, hard: true},
    {id: "Eb-", semitone: 3, name: Notes.E_FLAT, quality: Quality.minor, hard: true},
    {id: "D#-", semitone: 3, name: Notes.D_SHARP, quality: Quality.minor, hard: true},
    {id: "Ab-", semitone: 8, name: Notes.A_FLAT, quality: Quality.minor, hard: true},
    {id: "G#-", semitone: 8, name: Notes.G_SHARP, quality: Quality.minor, hard: true},
    {id: "C#-", semitone: 1, name: Notes.C_SHARP, quality: Quality.minor, hard: true},
    {id: "F#-", semitone: 6, name: Notes.F_SHARP, quality: Quality.minor, hard: false},
    {id: "B-", semitone: 11, name: Notes.B_NATURAL, quality: Quality.minor, hard: false},
    {id: "E-", semitone: 4, name: Notes.E_NATURAL, quality: Quality.minor, hard: false},
  ],
  findById: (quality, id) => KeyCenters[quality].find(e => e.id === id),
  findBySemitone: (quality, semitone) => KeyCenters[quality].find(e => e.semitone === semitone),
  random: (quality, filterPredicate) => _randomKeyCenter(KeyCenters[quality].filter(filterPredicate)),
};

function _scaleTones(key, scale) {
  const tones = [];
  for (let i = 0; i < scale.length; i++) {
    const semitone = (scale[i] + key.semitone) % MODULUS
    tones[i] = {
      semitone,
      name: _CHROMATIC_SCALE_TONES[key.id][semitone],
    }
  }
  return tones;
}

function _scale(tonality, key, scale, modes, chordQualities) {
  return {
    key: key,
    tonality: tonality,
    scaleTones: _scaleTones(key, scale),
    chordQualities: chordQualities,
    modes: [...modes],
    isSameAs: (scale) => scale.key && scale.key.id && scale.key.id === key.id
        && scale.tonality.id && scale.tonality.id === tonality.id,
    translate: (noteNumber) => _translateNote(noteNumber, _CHROMATIC_SCALE_TONES[key.id]),
  }
}

const Tonality = {
  major: {"id": "major", "name": "Major", "quality": Quality.major},
  melodicMinor: {"id": "melodicMinor", "name": "Melodic Minor", "quality": Quality.minor},
  harmonicMinor: {"id": "harmonicMinor", "name": "Harmonic Minor", "quality": Quality.minor},
}

const Qualities = {
  major: [
    ChordQuality.MAJOR,
    ChordQuality.MINOR,
    ChordQuality.MINOR,
    ChordQuality.MAJOR,
    ChordQuality.DOMINANT,
    ChordQuality.MINOR,
    ChordQuality.HALF_DIMINISHED,
  ],
  melodicMinor: [
    ChordQuality.MINOR_MAJOR,
    ChordQuality.MINOR,
    ChordQuality.AUGMENTED_MAJOR,
    ChordQuality.DOMINANT,
    ChordQuality.DOMINANT,
    ChordQuality.HALF_DIMINISHED,
    ChordQuality.HALF_DIMINISHED,
  ],
  harmonicMinor: [
    ChordQuality.MINOR_MAJOR,
    ChordQuality.HALF_DIMINISHED,
    ChordQuality.AUGMENTED_MAJOR,
    ChordQuality.MINOR,
    ChordQuality.DOMINANT,
    ChordQuality.MAJOR,
    ChordQuality.DIMINISHED,
  ]
}

const Modes = {
  major: [
    {name: "Ionian"},
    {name: "Dorian"},
    {name: "Phrygian"},
    {name: "Lydian"},
    {name: "Mixolydian"},
    {name: "Aeolian"},
    {name: "Locrian"},
  ],
  melodicMinor: [
    {name: "Melodic Minor" },
    {name: "Dorian", characteristic: `${Symbols.FLAT}2` },
    {name: "Lydian Augmented" },
    {name: "Lydian Dominant" },
    {name: "Mixolydian", characteristic: `${Symbols.FLAT}6`},
    {name: "Aeolian", characteristic: `${Symbols.FLAT}5`},
    {name: "Super Locrian" },
  ],
  harmonicMinor: [
    {name: "Harmonic Minor"},
    {name: "Locrian", characteristic: `${Symbols.NATURAL}6`},
    {name: "Ionian", characteristic: `${Symbols.SHARP}5`},
    {name: "Dorian", characteristic: `${Symbols.SHARP}4`},
    {name: "Phrygian Dominant"},
    {name: "Lydian", characteristic: `${Symbols.SHARP}2`},
    {name: "Super Locrian", characteristic: `${Symbols.DOUBLE_FLAT}7`},
  ]
}

const Scales = {
  major: [0, 2, 4, 5, 7, 9, 11],
  melodicMinor: [0, 2, 3, 5, 7, 9, 11],
  harmonicMinor: [0, 2, 3, 5, 7, 8, 11],
  select: (tonality, key) => _scale(tonality, key, Scales[tonality.id], Modes[tonality.id], Qualities[tonality.id]),
};

function _translateNote(noteNumber, scaleTones) {
  const semitone = noteNumber % MODULUS;
  const octave = Math.floor(noteNumber / MODULUS) + Protocol.OCTAVE_OFFSET;
  return {
    "id": noteNumber,
    "semitone": semitone,
    "name": scaleTones[semitone],
    "octave": octave,
  }
}

export {
  Quality,
  Qualities,
  KeyCenters,
  Tonality,
  Scales,
} 

