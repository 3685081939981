import React from "react";

import Container from "react-bootstrap/Container";

import Navigation from "./components/Navigation";
import FlashcardDeck from "./components/FlashcardDeck";

import {useChords, Generators} from "./model/chords";
import {Tonality} from "./model/scales";

import './App.scss';

export default function App() {
  const chords = useChords(null, null);

  React.useEffect(() => {
    chords.generator = Generators.progression(Tonality.major, [1, 4]);
  });

  return (
    <Container className="App" fluid>
      <Navigation/>
      <FlashcardDeck/>
    </Container>
  );
}
