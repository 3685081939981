import React from "react";
import ChordNotes from "./ChordNotes";
import ChordSymbol from "./ChordSymbol";

export default function Flashcard({chord, notes, correct, showBack, onFlip}) {
  return (
      <div className="flashcard" onClick={onFlip}>
        <div className={`flashcard-inner ${showBack ? "flashcard-rotate" : ""}`}>
          <div className="flashcard-front">
            <div/>
            <ChordSymbol chord={chord}/>
            <Mode mode={chord.mode}/>
            <ChordNotes notes={notes}/>
            {correct && <div>{correct && <span className="correct">{"\u2713"}</span>}</div>}
          </div>
          <div className="flashcard-back">
            <div className="key-center">
              Key of{" "}
              <span className="key-name">{chord.scale.key.name}</span>
              {" "}{chord.scale.tonality.name}
            </div>
            <ChordSymbol chord={chord}/>
            <Mode mode={chord.mode}/>
            <ChordNotes notes={chord.notes}/>
            {correct && <div>{correct && <span className="correct">{"\u2713"}</span>}</div>}
          </div>
        </div>
      </div>
  );
}


function Mode({ mode }) {
  return (
    <div>{mode.name} <span className="mode-characteristic">{mode.characteristic}</span></div>
  );
}