import Port from "./port";

export default class MIDI {

	_handle = null;
	_ports = {};
	_names = [];

	onReady;
	onPortsChanged;

	constructor(onReady, onPortsChanged) {
		this.onReady = onReady;
		this.onPortsChanged = onPortsChanged;
		this.fail = this.fail.bind(this);
		this.init = this.init.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.addPort = this.addPort.bind(this);
		this.removePort = this.removePort.bind(this);
	}

	start() {
		if (navigator.requestMIDIAccess) {
			navigator.requestMIDIAccess({sysex: true}).then(this.init, this.fail);
		}
		else {
			console.log("no MIDI available");
		}
	}

	fail() {
		console.log("MIDI access denied");
	}

	init(handle) {
		console.log("MIDI enabled");
		this._handle = handle;
		handle.addEventListener("statechange", this.handleStateChange);
		handle.inputs.forEach(input => this.addPort(input));
		this.onReady && this.onReady({"type": "ready", "ports": Object.values(this._ports)})
	}

	handleStateChange(event) {
		if (event.port.type !== "input") return;
		if (event.port.state === "connected") {
			const port = this.addPort(event.port);
			if (port) {
				this.onPortsChanged && this.onPortsChanged({"type": "add", port});
			}
		}
		else if (event.port.state === "disconnected") {
			const port = this.removePort(event.port.id);
			if (port) {
				this.onPortsChanged && this.onPortsChanged({"type": "remove", port});
				port.destroy();
			}
		}

	}

	addPort(eventPort) {
		if (eventPort.id in this._ports) return;
		const port = new Port(eventPort)
		this._ports[eventPort.id] = port;
		this._names.push({id: eventPort.id, name: eventPort.name});
		return port;
	}

	removePort(id) {
		if (!this._ports[id]) return;
		const port = this._ports[id];
		delete this._ports[id];
		const index = this._names.findIndex(e => e.id === id);
		if (index !== -1) {
			this._names.splice(index, 1);
		}
		return port;
	}

}