
function ChordSymbol({ chord }) {
	return (
		<div className="chord-symbol">
        	{chord.root.name}<ChordType type={chord.type}/>
		</div>
	);
}


function ChordType({ type }) {
  if (type === "69") {
    return <><sup>6</sup><sub>9</sub></>;
  }
  return <sup>{type}</sup>;
}


export default ChordSymbol;